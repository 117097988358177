.full-overlay {
  position: fixed;
  background: var(--violet);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: calc(100vh - 80px);
  width: 100vw;
  z-index: 99;
  padding: 80px 20px;
  color: var(--white);
  overflow: scroll;

  @media screen and (min-width: 992px) {
    padding: 100px 200px 40px;
  }
}
