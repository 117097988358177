.audio-image {
  margin: auto -20px;
  max-width: none;
}

.yara-overlay .full-overlay {
  z-index: 999999;
}

.yara {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  color: var(--mint);
  transition: opacity 0.3s;

  h1 {
    font-family: canada-type-gibson, sans-serif;
    font-weight: 600;
    font-size: 64px;
    margin-bottom: 8px;
    text-align: center;
  }

  p {
    margin-bottom: 24px;
  }

  img {
    margin-bottom: 20px;
    max-width: 60vw;
  }

  @media (min-height: 900px) {
    img {
      margin-bottom: 40px;
      max-width: 80vw;
    }
  }
  .phone-button {
    animation: pulsating 1.5s linear infinite;
  }
}

.yara--ended {
  h1 {
    font-size: 40px;
    margin-bottom: 80px;
  }

  .phone-button {
    transform: rotate(135deg);
    animation: none;
  }
}

.phone-button {
  background-color: var(--mint);
  border: none;
  cursor: pointer;
  height: 60px;
  width: 60px;
  border-radius: 60px;
  background-repeat: no-repeat;
  display: grid;
  place-items: center;
}

@keyframes pulsating {
  0%,
  20%,
  40%,
  60% {
    transform: scale(1);
  }

  10%,
  30%,
  50% {
    transform: scale(1.1);
  }
}

.timeline {
  border-radius: 4px;
  height: 1px;
  border: none;
  background-color: var(--mint);
  display: block;
  position: absolute;
  top: 16px;
  left: 20px;
  right: 20px;
  max-width: 800px;
}

.timeline-progress {
  content: "";
  position: absolute;
  height: 4px;
  background-color: var(--mint);
  left: 0;
  transform: translateY(-1.5px);
  transition: width 0.3s linear;
  border-radius: 4px;
}

.audio-buttons {
  display: flex;
  gap: 12px;
  flex-flow: row wrap;
  height: 46px;
  overflow: hidden;
}

.rewind-button,
.pause-play-button,
.forward-button {
  background-color: var(--white);
  border: none;
  cursor: pointer;
  height: 45px;
  width: 45px;
  border-radius: 45px;
  background-repeat: no-repeat;
}

.rewind-button {
  background-image: url(assets/rewind_icon.svg);
  background-position: 40% center;
  background-repeat: no-repeat;
}

.forward-button {
  background-image: url(assets/rewind_icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  transform: rotate(180deg);
}

.pause-play-button {
  background-image: url(assets/play_icon.svg);
  background-position: 60% center;
  background-repeat: no-repeat;
  background-size: 40%;
}

.pause-play-button--playing {
  background-color: var(--white);
  border: none;
  background-image: url(assets/pause_icon.svg);
  background-position: center;
}
