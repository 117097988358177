html {
  background-color: var(--violet);
  color: var(--white);
  font-family: canada-type-gibson, sans-serif;
}

/*
.main.container,
.root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: visible;
}
*/

iframe {
  width: 100%;
  margin: 0;
}

img {
  object-fit: contain;
}

figure {
  margin: 20px 0;

  img {
    margin: 0 auto;
  }
}

figcaption {
  margin-top: 10px;
}

.richtext {
  p,
  ul {
    margin: 20px 0;
    padding: 0;
  }

  li + li {
    margin-top: 20px;
  }

  a {
    color: inherit;
    overflow-wrap: break-word;
  }

  ul {
    list-style: none;
  }

  li:has(a) {
    position: relative;
    display: block;
    padding-left: 40px;
    margin: 20px 0;
  }

  li:has(a)::before {
    content: "";
    position: absolute;
    left: 4px;
    background: url(assets/link.svg) no-repeat;
    background-size: contain;
    width: 1em;
    height: 1em;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: monarcha, sans-serif;
  font-style: normal;
  margin: 20px 0;
  color: var(--mint);
}

h1 {
  font-size: 36px;
  font-weight: 700;
  line-height: 36px;
}

h2 {
  font-size: 33px;
  font-weight: 700;
  line-height: 33px;
}

.richtext,
.form,
.messages {
  font-family: canada-type-gibson, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
  line-height: 24.2px;
}

.start {
  color: black;
  background: var(--mint);

  h1 {
  }

  video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    top: 25%;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.main-container {
  width: 100%;
  min-height: 100vh;
  padding: 80px 20px 30px;
  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (min-width: 992px) {
    align-items: flex-start;
    padding: 100px 200px;
  }
}

.responsive-embed {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden;
}
.responsive-embed iframe,
.responsive-embed object,
.responsive-embed embed,
.responsive-embed video,
.flex-video iframe,
.flex-video object,
.flex-video embed,
.flex-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.responsive-embed.widescreen,
.flex-video.widescreen {
  /* padding-bottom: 56.25%; */
  padding-bottom: 166%; /* XXX is this correct for all videos? */
}

.f3cc {
  --f3cc-background-color: #333;
  --f3cc-foreground-color: #fff;
  --f3cc-button-background: #777;
  --f3cc-accept-background: #198619;

  .f3cc-title {
    font-weight: normal !important;
  }
}

.suppress-f3cc-modify .f3cc-button.modify {
  display: none;
}

.f3cc-modify {
  margin: auto 0 0 auto;
  color: inherit;
}

.message {
  border: 1px solid;
  border-radius: 99px;
  padding: 8px 16px 6px;

  &.success {
    color: #7cffe6;
  }
}

.hide {
  display: none !important;
}
