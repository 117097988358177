.answers-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  gap: 12px;
}

.answer {
  border: 4px solid var(--white);
  background-color: var(--white);
  color: var(--violet);
}

.answer--correct {
  background: #94e455;
  border: 4px solid #94e455;
}

.answer--wrong {
  background: #eb1111;
  border: 4px solid #eb1111;
  color: var(--white);
}

/* From https://css-tricks.com/snippets/css/shake-css-keyframe-animation/ */
@keyframes screenShake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.screen-shake {
  animation: screenShake 0.5s linear 1;
}
