.steps-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding: 0 0 50px 0;
  @media screen and (min-width: 992px) {
    min-width: 100%;
    padding: 0 0 200px 0;
  }
}

.steps_container__step {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 100%;
}

.buttons {
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 20px 20px;

  display: flex;
  gap: 12px;
  justify-content: space-between;
}

.buttons--shadow {
  padding-top: 32px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 30, 0.8);
  background-color: var(--violet);
}
