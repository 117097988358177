.menu {
  position: relative;
  display: grid;
  height: 100%;
  grid-auto-rows: 60px 30% auto;
}

.menu-title {
  margin-top: 2px;
}

.nav {
  display: block;

  .button {
    margin-bottom: 12px;
  }
}

.language-nav {
  display: flex;
  justify-self: end;
  align-self: end;
  gap: 12px;
}

.cancel-button {
  justify-self: start;
  align-self: start;
}

.menu-icon {
  position: absolute;
  right: 20px;
  @media screen and (min-width: 992px) {
    right: 200px;
  }
  top: 24px;
  display: block;
  width: 31.73px;
  height: 20.82px;
  cursor: pointer;
  z-index: 99999;
}

.menu-icon {
  color: var(--mint);
}

body.start:not(.menu) .menu-icon {
  color: black;
}
