.title-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  div {
    position: absolute;
    top: 30vh;
    bottom: -1000vh;

    --w: calc(-84vw - 10vh);
    right: var(--w);
    left: var(--w);

    pointer-events: none;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;

    @media (orientation: landscape) {
      top: 32vh;
      --w: calc(-30vw);
    }
  }
}

.title--start {
  position: absolute;
  z-index: 1;
}

.title__main {
  color: var(--violet);
  font-family: monarcha, sans-serif;
  font-weight: 700;
  font-size: 56px;
  line-height: 56px;
  margin: 0;
}

.title__sub {
  /*
  position: absolute;
  top: 80px;
  @media screen and (min-width: 576px) {
    top: 100px;
  }
  @media screen and (min-width: 992px) {
    top: 200px;
  }
  */
  font-family: canada-type-gibson, sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 28px;
  color: var(--darkgray);
  @media screen and (min-width: 992px) {
    font-size: 48px;
    line-height: 100%;
    width: 500px;
  }
}
