.content {
  display: flex;
  flex-direction: column;
  max-width: 800px;
}

.location-container {
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: 10px;
  @media screen and (max-width: 992px) {
    width: 100%;
  }
}

.title {
  margin-top: 10px;
}

.location-container__link {
  display: block;
  position: relative;
  margin-left: -20px;
  margin-right: -20px;
}

.location-container__link--visited::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(85, 44, 200, 0.4);
}

.location-container__button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1;
}

.location-container__image {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.location-container__icon {
  background-image: url(assets/arrow_forward.svg);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  background-color: var(--white);
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 100%;
}

.already-visited {
  margin-top: 5rem;
}
