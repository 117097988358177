.map {
  flex-basis: 300px;
  flex-grow: 0.9;
  width: 100%;
  max-width: 800px;
  border: 1px solid var(--white);
}

.mute-button {
  min-height: 45px;
  min-width: 45px;
  border-radius: 45px;
  background-image: url(assets/sound_on.svg);
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  border: none;
  background-color: var(--mint);
  align-self: start;
  margin-top: 20px;
}

.mute-button--muted {
  background-image: url(assets/sound_off.svg);
}

.watch {
  height: 2rem;
  width: 2rem;
  border-radius: 2rem;
  background: var(--violet) url(assets/gps_fixed.svg) no-repeat center;
  background-size: 1.5rem;
  cursor: pointer;
  border: none;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

@keyframes watching {
  0% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.watch--watching {
  animation: watching 2s ease-in-out infinite;
}

.watch--error {
  animation: none !important;
  filter: hue-rotate(150deg);
  opacity: 0.3;
  pointer-events: none;
}

.marker-label {
  height: 27px;
  padding: 3px 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  background-color: var(--white);
  border: 1px solid var(--violet);
  /* Actually not supposed to do this, but it works to have a constant left offset of 40px (set
  in Component.js */
  transform: translateX(50%);
}
