.input-wrapper {
  position: relative;

  display: grid;
  grid-template-columns: repeat(var(--cells), minmax(0, 1fr));
  gap: 8px;
  padding: 8px;
  margin-left: auto;
  margin-right: auto;
}

.input-wrapper--incorrect,
.input-wrapper--correct {
  box-shadow: 0 0 10px 0 var(--white);
}

.input-wrapper--incorrect {
  background: red;
}

.input-wrapper--correct {
  background: lightgreen;
}

.input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

.cell {
  background: white;
  color: black;
  display: block;
  height: 4rem;

  color: var(--violet);
  font-size: 3rem;
  font-weight: bold;

  display: grid;
  place-items: center;
}

.input-wrapper[data-cells="2"],
.input-wrapper[data-cells="3"] {
  max-width: 16rem;
}

.cell--focus {
  background: var(--mint);
  box-shadow: 0 0 5px 0 var(--mint);
}

.state-icon {
  display: grid;
  place-items: center;
  margin-top: 3rem;

  button {
    background: none;
    border: none;
    cursor: pointer;
  }
}

.code-step {
  .main-container {
    padding-top: 20px;
  }

  .buttons {
    bottom: 35%;
  }
}
