.button,
button {
  -webkit-appearance: none;
  display: inline-block;
  text-decoration: none;
  padding: 11px 16px;
  border: 2px solid var(--mint);
  border-radius: 46px;
  color: var(--royal-blue);
  cursor: pointer;
  background: var(--mint);
  color: var(--violet);
  font-family: canada-type-gibson, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  font-style: normal;
}

.button.ml {
  margin-left: auto;
}

.button--dark {
  background: var(--violet);
  border: 2px solid var(--violet);
  color: var(--white);
}

.button--light {
  background: var(--mint);
  border: 2px solid var(--mint);
  color: var(--violet);
}

.button--disabled {
  opacity: 0.5;
  cursor: default;
}

.page-content .button {
  margin-top: 12px;
}
